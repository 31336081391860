@import 'main.scss';
.closeBtn {
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 3;
  transition:
    width 0.5s,
    height 0.5s,
    background 0.5s,
    border 0.5s;
  z-index: 110;

  @media screen and (min-width: 1000px) {
    width: 90px;
    height: 90px;
  }

  button {
    height: 38px;
    width: 38px;

    @media screen and (min-width: 1000px) {
      height: 48px;
      width: 48px;
    }
  }

  svg {
    height: 38px;
    width: 38px;

    @media screen and (min-width: 1000px) {
      height: 48px;
      width: 48px;
    }

    transition:
      width 0.5s,
      height 0.5s;
  }

  @media screen and (min-width: 1000px) {
    @media screen and (hover: hover) {
      &:hover {
        background: var(--secondary);
        height: 90px;
        width: 90px;

        svg {
          rect {
            fill: var(--white);
          }
        }
      }
    }
  }
}
