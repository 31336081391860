@import 'main.scss';
.submitBtn {
  transform: scale(1);
  cursor: pointer;
  bottom: 24px;
  right: 24px;
  width: 150px;
  height: 45px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 22.5em;

  @media screen and (min-width: 1000px) {
    height: 55px;

    bottom: 60px;
    right: 60px;
    width: 205px;
    padding: 16px 0;
  }

  &.hoverButton {
    .text {
      transition: all 0.2s ease-in-out;

      @media screen and (hover: hover) {
        &:hover {
          background: var(--primary);
        }
      }
    }
  }

  .text {
    position: relative;
    z-index: 12;
    color: #fff;
    font-family: var(--font-avant);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    background: none;
    border-radius: 22.5em;
    width: 100%;
    padding: 16px 0;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;

    @media screen and (min-width: 1000px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .submitBtnBase {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 22.5em;
    background-color: #eee1f4;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.1, 1);
  }

  .submitBtnBaseBg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    transform: scale3d(1, 0, 1);
    transform-origin: bottom;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.1, 1);
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    .spinnerCircle {
      position: relative;
      width: 28px;
      height: 28px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 100%;
      animation: spin 1s infinite linear;

      .spinnerCircleInner,
      .spinnerCircleGradient {
        position: absolute;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
      }

      .spinnerCircleInner {
        top: 50%;
        left: 50%;
        background-color: var(--secondary);
        border-radius: 100%;
      }

      .spinnerCircleGradient {
        top: -24px;
        left: 8px;
        background-color: #fff;
        background-image: -moz-linear-gradient(
          0deg,
          #fff 0%,
          var(--secondary) 50%
        );
        background-image: -webkit-linear-gradient(
          0deg,
          #fff 0%,
          var(--secondary) 50%
        );
        background-image: linear-gradient(0deg, #fff 0%, var(--secondary) 50%);
        transform: rotate(-45deg);
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
