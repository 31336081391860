@import 'main.scss';
.dialogText {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: 1000px) {
    align-items: center;
  }

  h2 {
    font-family: var(--font-avant);
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-transform: uppercase;

    @media screen and (min-width: 1000px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 60px;
      line-height: 70px;
    }

    @media screen and (min-width: 1600px) {
      font-size: 70px;
      line-height: 80px;
    }
  }

  p {
    font-family: var(--font-sora);
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;

    @media screen and (min-width: 1000px) {
      font-size: 26px;
      line-height: 33px;
    }
  }

  .formInputContainer {
    margin-top: 32px;
    max-width: 750px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @media screen and (min-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "description description" "currency cv";
    }

    label {
      color: var(--black);
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      position: absolute;
      pointer-events: none;
      left: 16px;
      width: 100%;
      top: 4px;
    }

    .currency {
      font-family: var(--font-sora);
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      color: #8c8c8c;
      position: absolute;
      left: 14px;
      bottom: 9px;
      z-index: 3;
      opacity: 0;
      transition: all 400ms;
    }

    .fieldContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      position: relative;

      @media screen and (min-width: 1000px) {
        flex-direction: row;
      }

      span {
        font-family: var(--font-sora);
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;

        &.errorCVLabel {
          position: absolute;
          color: red;
          font-size: 11px;
          font-weight: 500;
          left: 50%;
          transform: translateX(-50%);
          bottom: -26px;
          @media screen and (min-width: 1000px) {
            width: 100%;
          }
        }
      }

      .iconContainer {
        width: fit-content;
        margin-top: 5px;
        margin-right: 14px;
      }
    }

    .inputContainer {
      display: flex;
      width: 100%;
      position: relative;
      align-items: center;
      font-family: var(--font-sora);
      border: 1px solid #8c8c8c;
      border-radius: 5px;

      &:focus-within .currency,
      .currency.noEmpty {
        opacity: 1;
      }

      input {
        width: 100%;
        border-radius: 5px;
        font-size: 20px;
        line-height: 26px;
        transition: all 0.3s;
        outline: none;
        box-sizing: border-box;
        position: relative;
        user-select: none;
      }

      .errorMessage {
        position: absolute;
        content: "";
        bottom: -15px;
        left: 0px;
        color: red;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .borderError {
        border: 1px red solid;
      }

      .errorLabel {
        color: red;
      }

      input:focus + label,
      input.noEmpty + label {
        top: 0px;
        left: 14px;
        font-size: 11px;
        font-weight: 400;
        transform: translateY(0%);
        line-height: 24px;

        &.errorLabel {
          transition: all 0.3s;
          color: red;
        }
      }
    }

    input,
    textarea {
      cursor: none;
      height: 52px;
      padding: 20px 30px 5px;
      gap: 10px;
      border-radius: 5px;
      font-size: 20px;
      line-height: 26px;
      font-family: var(--font-sora);
      border: none;
    }

    .fileContainer {
      .inputContainer {
        border: 1px solid var(--Grey-medium, #8c8c8c);
        border-radius: 5px;
        cursor: none;

        input {
          opacity: 0;
          cursor: none;
        }
        label,
        span {
          height: fit-content;
          left: 25px;
          width: 85%;
          white-space: nowrap;
          text-overflow: ellipsis;
          right: 0;
          overflow: hidden;
        }
        .fileName {
          display: none;
        }
      }
    }

    .isFile {
      .inputContainer {
        min-width: 170px;
        width: 100%;
        border: 1px solid #8c8c8c;
        background-color: var(--white);

        input {
          max-width: 90%;
        }

        .iconContainer {
          display: block;
          @media screen and (hover: hover) {
            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .fileName {
          display: block;
          position: absolute;
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          left: 14px;
          bottom: 10px;
        }
      }
    }

    .areaContainer {
      position: relative;
      grid-area: description !important;

      textarea {
        height: 150px;
        width: 100%;
        resize: none;
        position: relative;
        border: 1px solid #8c8c8c;
        padding: 22px 16px 5px;
        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 40px;
          background-color: rgb(155, 155, 155);

          border: 3px solid var(--white);
        }
      }

      textarea:focus + label,
      textarea.noEmpty + label {
        width: calc(100% - 32px);
        left: 16px;
        top: 1px;
        font-size: 11px;
        font-weight: 500;
        transform: translateY(0%);
        background-color: rgba(255, 255, 255);
        line-height: 26px;
      }
    }
  }
}

.dialogButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
