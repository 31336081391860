@import 'main.scss';
.choiceContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.625em;
  width: 100%;
  font-size: 0.75em;
  text-transform: uppercase;
  display: none;

  @media screen and (min-width: 1000px) {
    display: flex;
  }

  .choice {
    border-radius: 6.25em;
    font-weight: 500;
    border-radius: 500px;
    border: 1px solid var(--Grey-medium, #8c8c8c);
    background: #fff;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: 1px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    max-width: 300px;

    color: var(--Primary, #17082f);
    font-family: var(--font-sora);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    &.active {
      // width: 100%;
      width: max-content;
      padding: 10px 24px;
      display: block;
      opacity: 1;
    }
  }
}
