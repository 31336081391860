@import 'main.scss';
:root {
  @each $key, $hex in $color {
    --#{$key}: #{$hex};
  }

  --header-height: 145px;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  cursor: none;
}

*::before,
*::after {
  display: block;
}

body {
  overflow-x: hidden;
  cursor: none;
}

img,
picture,
video,
iframe,
figure {
  max-width: 100%;
  width: inherit;
  display: block;
  object-fit: cover;
  object-position: center;
}

img:before {
  font-size: 30px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ececec;
  text-align: center;
  font-family:
    var(--font-sora),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

p a {
  display: inline;
}

li {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
strong,
blockquote,
i,
b,
u,
em {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  color: inherit;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

form,
textarea,
select,
button,
label {
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
  appearance: unset;

  &:focus-visible {
    /* remove default focus style */
    outline: none;
  }
}

table,
tr,
td {
  border-collapse: collapse;
  border-spacing: 0;
}

.title-section {
  font-family: var(--font-avant);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 45px;
  line-height: 55px;
  -webkit-text-stroke-width: 2px;

  @media screen and (min-width: 400px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media screen and (min-width: 1001px) {
    font-size: 100px;
    line-height: 100px;
    -webkit-text-stroke-width: 3px;
  }

  @media screen and (min-width: 1201px) {
    font-size: 120px;
    line-height: 120px;
    -webkit-text-stroke-width: 4px;
  }

  @media screen and (min-width: 1601px) {
    font-size: 145px;
    line-height: 145px;
  }
}

.first-line-title {
  background-attachment: scroll;
  background-clip: text;
  background-repeat: no-repeat;
  color: transparent;
  background-image: linear-gradient(-90deg, #17082f80 50%, var(--primary) 50%);
  background-size: 200% 100%;
  width: max-content;
}

.second-line-title {
  background-attachment: scroll;
  background-clip: text;
  background-repeat: no-repeat;
  color: transparent;
  background-image: linear-gradient(-90deg, #17082f80 50%, var(--primary) 50%);
  background-size: 200% 100%;
  width: max-content;
}

.title-outline {
  width: fit-content;
  -webkit-text-stroke: 3px var(--primary);
  paint-order: stroke fill;
  color: var(--background);

  @media screen and (min-width: 1000px) {
    -webkit-text-stroke: 5px var(--primary);
  }

  @media screen and (min-width: 1200px) {
    -webkit-text-stroke: 6px var(--primary);
  }
}

.title-outline-white {
  -webkit-text-stroke: 3.79px var(--white);
  paint-order: stroke fill;
  color: transparent;
}

.container {
  width: 100%;
  max-width: 700px;
  padding: 0 24px;
  margin: 0 auto;

  @media screen and (min-width: 1000px) {
    padding: 0 50px;
    max-width: 100%;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1900px;
    padding: 0 130px;
  }
}

.container-header {
  width: 100%;
  max-width: 748px;
  padding: 24px 24px 0px 24px;
  margin: 0 auto;

  @media screen and (min-width: 1000px) {
    padding: 40px 50px 0px 50px;
    max-width: 100%;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1900px;
    padding: 40px 50px 0px 50px;
  }
}

.container-blog {
  width: 100%;
  max-width: 700px;
  padding: 32px 24px 0px 24px;
  margin: 0 auto;

  @media screen and (min-width: 1000px) {
    padding: 40px 50px 0px 50px;
    max-width: 1100px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1240px;
    padding: 40px 0 0 0;
  }
}

.font-sora {
  font-family: var(
    --font-sora,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif
  );
}

.font-avant {
  font-family: var(
    --font-avant,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif
  );
}

.font-avant-regular {
  font-family: var(
    --font-avant-regular,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif
  );
}

.content {
  width: 100%;
  position: relative;
}

.bg {
  background-color: var(--background);
}

.overflowHidden {
  overflow: hidden;
}

.overflowXHidden {
  overflow-x: hidden;
}
